<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card
      v-if="documentoslist"
      class="mx-auto"
      max-width="800"
    >
      <v-row style="margin: auto;">
        <v-spacer />
        <v-btn
          color="primary"
          fab
          dark
          small
          class="mb-2"
          @click="addDocuments()"
        >
          <v-icon
            dark
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </v-row>

      <v-sheet class="pa-4 primary light-blue">
        <v-text-field
          v-model="search"
          label="Buscar documento"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        />
        <v-checkbox
          v-model="caseSensitive"
          dark
          hide-details
          label="Búsqueda sensible Mayúsculas/Minúsculas"
        />
      </v-sheet>
      <v-card-text>
        <v-treeview
          :items="documentos"
          :search="search"
          :filter="filter"
          :open.sync="open"
          :loading="loading"
          item-disabled="locked"
          activatable
          open-on-click
          input
          @update:active="onUpdate"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon
              v-if="item.children"
              v-text="`mdi-${open ? 'folder-open' : 'folder'}`"
            />
            <v-icon
              v-else
              v-text="`mdi-${(item.file) ? 'file-pdf' : 'folder'}`"
            />
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
    <addDocumentos
      :edited-item="datos"
      :edited-index="editedIndex"
    />
  </v-container>
</template>

<script>
  // import Vue from 'vue'
  import addDocumentos from './addDocumentos.vue'
  export default {
    name: 'DocumentosAdmin',
    components: {
      addDocumentos,
    },
    data: () => ({
      items: [
        {
          id: 1,
          name: 'Documentos de la fundación',
          children: [
            {
              id: 2,
              name: 'Formación',
              children: [
                {
                  id: 201,
                  name: 'Contenido_curso_enero.pdf',
                },
                {
                  id: 202,
                  name: 'Contenido_curso_febrero.pdf',
                },
                {
                  id: 203,
                  name: 'Contenido_curso_marzo.pdf',
                },
                {
                  id: 204,
                  name: 'Contenido_curso_abril.pdf',
                },
                {
                  id: 205,
                  name: 'Contenido_curso_mayo.pdf',
                },
              ],
            },
            {
              id: 3,
              name: 'Inserción socio-laboral',
              children: [
                {
                  id: 301,
                  name: 'Formato_inserción_laboral.pdf',
                },
                {
                  id: 302,
                  name: 'Programa_insersión_social.pdf',
                },
              ],
            },
            {
              id: 4,
              name: 'Acogida, orientación y asesoramiento',
              children: [
                {
                  id: 401,
                  name: 'Protocolo_acogida.pdf',
                },
                {
                  id: 402,
                  name: 'Protocolo_orientación.pdf',
                },
                {
                  id: 403,
                  name: 'Protocolo_asesoramiento.pdf',
                },
              ],
            },
          ],
        },
      ],
      open: [],
      search: null,
      caseSensitive: false,
      datos: {},
      editedIndex: -1,
    }),
    computed: {
      filter () {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      },
      documentos: {
        get () {
          return this.$store.state.documentos.documentos
        },
      },
      documentoslist: {
        get () {
          return this.$store.state.documentos.documentoslist
        },
      },

      loading: {
        get () {
          return this.$store.state.documentos.loading
        },
      },
    },
    mounted () {
      this.$store.dispatch('documentos/getItemsDocumentos')
    },
    methods: {
      addDocuments () {
        this.datos = {}
        this.editedIndex = -1
        this.$store.commit('documentos/setRegister', true)
      },
      async onUpdate (selection) {
        // console.log(selection)
        if (selection.length > 0) {
          var response = await this.$store.dispatch('documentos/downloadDocument', selection)
          var nombre = await this.$store.dispatch('documentos/getNombreDocument', selection)

          this.forceFileDownload(response, nombre)
        }
      },
      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },
    },
  }
</script>
